import React, { useState, useRef } from "react"
import { API_URL } from "../../../utils/helpers"
import { db } from "../../../utils/firebase"
import Input from "../../Input"
import Msg from "../../Msg"
import Submit from "../../Submit"
import { Container } from "./styled"
import { Form } from "../../../elements/Layout/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRoute, faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"
import {
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({})
  const [msg, setMsg] = useState({})

  const formElement = useRef(null)

  const site = "demo"

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/${site}email`)
    let data = new FormData()
    Object.entries(names).forEach(([key, value]) => data.append(key, value))
    data.append("site", site || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        db.collection(`emails`)
          .add({
            site: site,
            email: names.email,
            comments: names.comments,
            createdAt: new Date(),
          })
          .then(function(docRef) {
            //console.log("Document written with ID: ", docRef.id)
          })
          .catch(function(error) {
            console.error("Error adding document: ", error)
          })

        formElement.current.reset()
        handleUpdate("email", "")
        handleUpdate("comments", "")
        setMsg({
          type: "success",
          text: json.text,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Container>
      <div>
        <div>
          <h2>Order Pickup</h2>
          <p>
            2915 Jupiter Park Drive Suite 200
            <br />
            Jupiter, FL 33458
          </p>
          <span>
            <a href="https://goo.gl/maps/pv5kEjdEpuYrc58k9">
              <FontAwesomeIcon icon={faRoute} size="2x" />
            </a>
          </span>
        </div>

        <div>
          <h2>Reach Out</h2>
          <p>
            (561) 776-9600
            <br />
            <a href="mailto:info@qnewmedia.com">info@qnewmedia.com</a>
          </p>
          <span>
            <a href="https://www.instagram.com">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="https://www.facebook.com">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a href="https://www.twitter.com">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
          </span>
        </div>
        <div>
          <h2>Send an Email</h2>

          <Form
            method="post"
            action="/"
            onSubmit={e => handleSubmit(e)}
            className="email"
            ref={formElement}
          >
            <div>
              <Input
                type="text"
                label="Email"
                req={true}
                name="email"
                value={names.email}
                update={handleUpdate}
                errors={errors}
                autocomplete="email"
                smaller={true}
              />
            </div>
            <div>
              <Input
                type="text"
                label="Message"
                req={true}
                name="comments"
                value={names.comments}
                update={handleUpdate}
                errors={errors}
                smaller={true}
              />
            </div>

            <div className="msg-submit">
              {msg.type && <Msg data={msg} />}

              <div
                className={
                  msg.type === "working" ? "hidden" : "submit-container"
                }
              >
                <Submit
                  name="Send Email"
                  icon={faChevronCircleRight}
                  smaller={true}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>

      <p className="copy">
        © 2020 Menuline Online Ordering. All rights reserved.
      </p>
    </Container>
  )
}

export default Footer

import React from "react"
import Helmet from "react-helmet"
import icon from "../../img/q-logo.png"

const Index = ({ index = false, title = "", descr }) => {
  return (
    <Helmet>
      <title>Take Out & Curbside Pickup Ordering / Demo Restaurant</title>
      <meta name="description" content={descr} />
      <meta name="robots" content="index,follow" />
      <meta name="copyright" content="q new media inc." />
      <meta name="rating" content="general" />
      <link rel="shortcut icon" href={icon} />
    </Helmet>
  )
}

export default Index

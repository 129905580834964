import styled from "styled-components"

export const Container = styled.div`
  padding: 25px;
  background-color: #fffff0;
  border-bottom: 1px solid var(--border);
  position: relative;

  & > button {
    position: absolute;
    top: 7.5px;
    right: 7.5px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0 7.5px;
    font: inherit;
    cursor: pointer;
    outline: none;
    border: 1px solid var(--border);
    color: var(--blue);

    & > * {
    }
  }

  & > p {
    font-size: 0.9rem;
    margin-bottom: 15px;

    & > a {
      border-bottom: 1px solid var(--blue);
    }
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`

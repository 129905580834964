import React from "react"
import { Container } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

const Welcome = ({ close }) => {
  return (
    <Container>
      <button type="button" onClick={() => close()}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <p>
        Welcome, this site is a functional demo of the Menuline online ordering
        platform. Feel free to test drive the process (including checkout) to
        “place” your order.
      </p>

      <p>
        Fully customizable, your restaurant can have online ordering
        capabilities added to your existing web site or we provide a
        fully-hosted link to a stand-alone site, complete with payment
        processing and notification options. Additionally, menu updates are easy
        and timing functions for auto opening/closing online ordering
        avalability are built in.
      </p>

      <p>
        Please contact Kylan at{" "}
        <a href="mailto:&#107;&#121;&#108;&#097;&#110;&#064;&#113;&#110;&#101;&#119;&#109;&#101;&#100;&#105;&#097;&#046;&#099;&#111;&#109;">
          &#107;&#121;&#108;&#097;&#110;&#064;&#113;&#110;&#101;&#119;&#109;&#101;&#100;&#105;&#097;&#046;&#099;&#111;&#109;
        </a>{" "}
        or (561) 719-3855 for more information.
      </p>
    </Container>
  )
}

export default Welcome
